import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import Text from '../../../components/ui/text';
import { useLocale } from '../../../context/locale.context';
import { getCopy } from '../../../helpers/copy';
import Container from '../../../modules/container';
import Layout from '../../../modules/layout';
import * as styles from './styles.module.scss';
import Headline from '../../../components/ui/headline';

const LandingPageFormConfirmation = () => {
  const [type] = useQueryParam('type', StringParam);
  const { locale } = useLocale();
  const { allContentfulMicrocopy } = useStaticQuery(
    graphql`
      query {
        allContentfulMicrocopy {
          nodes {
            node_locale
            key
            value
          }
        }
      }
    `
  );
  const microcopy = allContentfulMicrocopy.nodes
    .filter((node) => node.node_locale === locale)
    .reduce((acc, curr) => {
      return {
        ...acc,
        [curr.key]: curr.value,
      };
    }, {});

  const getConfirmationMessage = () => {
    switch (type) {
      case 'ifda':
      case 'order':
      case 'regular':
        return getCopy('contactForm.submitted', microcopy);
      case 'forDownload':
        return getCopy('contactForm.order.submitted', microcopy);
      case 'withKeyword':
      case 'withoutKeyword':
        return getCopy('contactForm.landing.submitted', microcopy);
      default:
        return getCopy('contactForm.submitted', microcopy);
    }
  };

  return (
    <Layout
      title={getCopy('title.contactFormPageConfirmation', microcopy)}
      description={getCopy('desc.contactFormPageConfirmation', microcopy)}
      slug={'form/confirmation'}
    >
      <Container className={styles.wrapper}>
        <Headline
          level="h2"
          type="h2"
          text={getCopy('contactForm.confirmation.headline', microcopy)}
          className={styles.headline}
        />
        <Text as="span" type="p1" id="fully-completed-form">
          {getConfirmationMessage()}
        </Text>
      </Container>
    </Layout>
  );
};

export default LandingPageFormConfirmation;
